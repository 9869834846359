import { api } from "~/trpc/react";
import { useProducerInContext } from "../../hooks/use-producer";
import { FirstTimeProducerIdentitySetupModal } from "./ProducerOnboarding/FirstTimeProducerIdentitySetupModal";
import { ProducerWelcomeModal } from "./ProducerOnboarding/ProducerWelcomeModal";

export const ProducerOnboarding = () => {
  const producer = useProducerInContext();
  const { data: onboarding } = api.producer.onboarding.checklist.useQuery();

  if (!onboarding) return null;
  return (
    <>
      {!onboarding.completedProducerWelcome ? (
        <ProducerWelcomeModal />
      ) : producer && !producer.first_time_setup_at ? (
        <FirstTimeProducerIdentitySetupModal producer={producer} />
      ) : null}
    </>
  );
};
