"use client";

import { Center, Loader } from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import { ReactNode, Suspense, useEffect } from "react";
import { useUser } from "reactfire";
import { AuthGuard } from "~/app/components/AuthGuard";
import { api } from "~/trpc/react";
import { ProducerTermsAndConditionsForm } from "./ProducerTermsAndConditionsForm";

const CenteredKiwiLoader = (props: { "data-testid"?: string }) => (
  <Center mt="xl" data-testid={props["data-testid"]}>
    <Loader />
  </Center>
);

export const ProducerGuard = ({ children }: { children: ReactNode }) => {
  const { data: currentUser } = useUser();
  const {
    data: userRoles,
    isLoading: isLoadingRoles,
    refetch: refetchUserRoles,
  } = api.account.roles.useQuery();

  const allProducersQuery = api.producer.list.useQuery();
  const [producerId, setProducerId] = useLocalStorage({
    key: "producer-id",
  });

  const producer =
    allProducersQuery.data?.find((producer) => producer.id === producerId) ??
    allProducersQuery.data?.[0];

  // biome-ignore lint/correctness/useExhaustiveDependencies: refetch the user roles when the user changes
  useEffect(() => {
    refetchUserRoles();
  }, [currentUser, refetchUserRoles]);

  useEffect(() => {
    if (producer && producer.id !== producerId) setProducerId(producer.id);
  }, [producer, producerId, setProducerId]);

  if (isLoadingRoles || allProducersQuery.isLoading)
    return <CenteredKiwiLoader data-testid="loading" />;

  if (!userRoles?.isProducer || !userRoles?.terms?.hasAcceptedLatestProducerTerms)
    return <ProducerTermsAndConditionsForm />;

  // don't render children unless the producer id in local storage has definitely been set
  if (!producerId) return <CenteredKiwiLoader data-testid="loading" />;

  return children;
};

export const ProducerAuthGuard = ({ children }: { children: ReactNode }) => {
  return (
    <AuthGuard>
      <ProducerGuard>
        <Suspense fallback={<CenteredKiwiLoader data-testid="loading" />}>{children}</Suspense>
      </ProducerGuard>
    </AuthGuard>
  );
};
