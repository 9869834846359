import { z } from "zod";

export const producerFormSchema = z.object({
  id: z.string().optional(),
  name: z.string().min(1),
  contactEmail: z.string().email(),
  avatarUrl: z.string().optional(),
  avatar: z.object({ name: z.string(), data: z.string() }).optional(),
});

export type ProducerForm = z.infer<typeof producerFormSchema>;
