"use client";

import {
  Button,
  Card,
  Divider,
  Stack,
  Text,
  rem,
  TextInput,
  Group,
  Avatar,
  Flex,
} from "@mantine/core";
import { Dropzone, FileWithPath, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { useForm, zodResolver } from "@mantine/form";
import { modals } from "@mantine/modals";
import { TRPCClientError } from "@trpc/client";
import { useMemo, useState } from "react";
import { ProducerForm, producerFormSchema } from "~/lib/schemas/producers";
import { api } from "~/trpc/react";
import { IconPhotoCheck, IconPhotoUp, IconPhotoX } from "@tabler/icons-react";
import { producers } from "@prisma/client";

export const ProducerIdentityForm = ({ producer }: { producer: producers }) => {
  const { mutateAsync: updateProducerIdentity, isPending } =
    api.producer.identity.update.useMutation();
  const utils = api.useUtils();

  const [file, setFile] = useState<FileWithPath>();
  const [uploadError, setUploadError] = useState<string>();
  const filePreviewUrl = useMemo(() => (file ? URL.createObjectURL(file) : undefined), [file]);
  const imagePreview = filePreviewUrl ? (
    <Avatar
      key={filePreviewUrl}
      src={filePreviewUrl}
      alt={file?.path ?? "Uploaded avatar"}
      size={96}
      radius={"50%"}
    />
  ) : producer.avatar_url ? (
    <Avatar
      key={producer.avatar_url}
      src={producer.avatar_url}
      alt={file?.path ?? "Uploaded avatar"}
      size={96}
      radius={"50%"}
    />
  ) : (
    <Avatar alt={file?.path ?? "Image upload placeholder"} size={96} radius={"50%"}>
      <IconPhotoUp size={36} stroke={1.5} />
    </Avatar>
  );

  const form = useForm<ProducerForm>({
    mode: "uncontrolled",
    initialValues: {
      name: producer.name.trim(),
      contactEmail: producer.contact_email.trim(),
    },
    validate: zodResolver(producerFormSchema),
  });

  const onSubmit = form.onSubmit(async (values) => {
    try {
      await updateProducerIdentity({
        ...values,
        id: producer.id,
        avatar: file
          ? {
              name: file.name,
              data: Buffer.from(await file.arrayBuffer()).toString("base64"),
            }
          : undefined,
      });
      await utils.producer.list.refetch();
      await utils.producer.onboarding.checklist.refetch();
    } catch (e) {
      modals.open({
        title: "Failed to create producer identity",
        centered: true,
        children: (
          <>
            <Text>
              {e instanceof TRPCClientError
                ? e.message
                : "Something went wrong creating your producer identity. Please double-check your details and try again."}
            </Text>
            <Button fullWidth onClick={() => modals.closeAll()} mt="md">
              Okay
            </Button>
          </>
        ),
      });
    }
  });

  return (
    <form onSubmit={onSubmit}>
      <Stack>
        <Divider mx={rem(-16)} />

        <Card variant="hint">
          <Text size="xs">
            Your producer identity is the profile seen by customer when viewing and buying a ticket
            to your event — for example this could be anything from company branding to a personal
            profile or stage name.
          </Text>
        </Card>

        <Stack gap={0}>
          <Text>Profile image</Text>
          <Dropzone
            disabled={isPending}
            onDrop={(files) => {
              setUploadError(undefined);
              setFile(files[0]);
            }}
            onReject={([file]) => {
              const error = file.errors[0];
              switch (error.code) {
                case "file-invalid-type":
                  setUploadError("Uploaded file must be an image (png, gif, jpeg, or webp).");
                  break;
                case "file-too-large":
                  setUploadError("Image file size exceeds 1mb. Please upload a smaller image.");
                  break;
                default:
                  setUploadError(error.message);
              }
              setFile(undefined);
            }}
            maxSize={1024 ** 2}
            accept={IMAGE_MIME_TYPE}
            multiple={false}
          >
            <Group justify="center" p="xs" style={{ pointerEvents: "none" }}>
              <Dropzone.Accept>
                <Flex gap="md" align="center" w="100%">
                  <IconPhotoCheck size={96} stroke={1.5} />
                  <Stack gap={0} align="start" style={{ flexGrow: 1 }}>
                    <Text size="sm" fw={600}>
                      Looking good!
                    </Text>
                    <Text size="sm" c="dimmed">
                      Drop to use this image
                    </Text>
                  </Stack>
                </Flex>
              </Dropzone.Accept>

              <Dropzone.Reject>
                <Flex gap="md" align="center" w="100%">
                  <Text c="red">
                    <IconPhotoX size={96} stroke={1.5} />
                  </Text>
                  <Stack gap={0} align="start" style={{ flexGrow: 1 }}>
                    <Text size="sm" fw={600}>
                      File type not supported
                    </Text>
                    <Text size="sm" c="dimmed">
                      Only images (png, gif, jpeg, or webp) are supported
                    </Text>
                  </Stack>
                </Flex>
              </Dropzone.Reject>

              <Dropzone.Idle>
                <Flex gap="xl" align="center" w="100%">
                  {imagePreview}

                  {uploadError ? (
                    <Stack gap={0} align="start" style={{ flexGrow: 1 }}>
                      <Text size="sm" fw={600}>
                        Failed to upload image
                      </Text>
                      <Text size="sm" c="red">
                        {uploadError}
                      </Text>
                    </Stack>
                  ) : (
                    <Stack gap={0} align="start" style={{ flexGrow: 1 }}>
                      <Text size="sm" fw={600}>
                        Drag a new image here or click to select one
                      </Text>
                      <Text size="sm" c="dimmed">
                        File size should not exceed 1mb
                      </Text>
                    </Stack>
                  )}
                </Flex>
              </Dropzone.Idle>
            </Group>
          </Dropzone>
        </Stack>

        <TextInput required label="Name" {...form.getInputProps("name")} />

        <Divider mx={rem(-16)} />

        <Stack gap={0}>
          <TextInput required label="Contact email" {...form.getInputProps("contactEmail")} />
          <Text size="xs" c="dimmed">
            Used to direct your customer support queries. Customers can't see your email but they
            can use a form on the website to get in touch with you.
          </Text>
        </Stack>

        <Divider mx={rem(-16)} />

        <Button type="submit" variant="attention" loading={isPending}>
          Create
        </Button>
      </Stack>
    </form>
  );
};
