"use client";

import { AppShell, MantineProvider } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { ProducerAuthGuard } from "./ProducerAuthGuard";
import { ProducerBottomNavigationBar } from "./ProducerLayout/ProducerBottomNavigationBar";
import { ProducerSidebar } from "./ProducerLayout/ProducerSidebar";
import { producerTheme } from "../theme";
import { ModalsProvider } from "@mantine/modals";
import { usePathname } from "next/navigation";
import { ProducerOnboarding } from "./ProducerLayout/ProducerOnboarding";

type Props = {
  children?: JSX.Element;
};

export const ProducerLayout = ({ children }: Props) => {
  const desktopLayout = useMediaQuery("(min-width: 768px)");
  const pathname = usePathname();
  const spaceForPageFilters = pathname?.startsWith("/producer/events") ? 66 : 0;

  return (
    <MantineProvider theme={producerTheme} defaultColorScheme="auto" classNamesPrefix="producer">
      <ModalsProvider>
        <ProducerAuthGuard>
          <AppShell
            navbar={{
              width: 300,
              breakpoint: "sm",
              collapsed: { mobile: true },
            }}
            footer={{ height: 80 + spaceForPageFilters, collapsed: desktopLayout }}
            padding="xs"
          >
            <ProducerOnboarding />
            <ProducerSidebar />
            <ProducerBottomNavigationBar />
            <AppShell.Main>{children}</AppShell.Main>
          </AppShell>
        </ProducerAuthGuard>
      </ModalsProvider>
    </MantineProvider>
  );
};
