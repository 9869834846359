import { Anchor, Button, Center, Container, Stack, Title } from "@mantine/core";
import Link from "next/link";

export const ProducerTerms = () => (
  <Container size="md">
    <Stack my="xl">
      <Title order={1}>Terms and Conditions</Title>
      <Title order={2}>Producer Terms and Conditions</Title>

      <ProducerTermsContent />

      <br />

      <Center>
        <Link href="https://firebasestorage.googleapis.com/v0/b/kiwiticket-assets/o/KiwiTicket%20Producer%20Terms%20and%20Conditions.pdf?alt=media&token=b4655823-e6ae-4ba4-b037-cb4803ea7836">
          <Button>Download as a PDF</Button>
        </Link>
      </Center>
    </Stack>
  </Container>
);

export const ProducerTermsContent = () => (
  <ol>
    <li>
      Application and variation of producer terms and conditions
      <ol>
        <li>
          These terms and conditions set out the terms and conditions that apply to all listings of
          Events you make on the KiwiTicket website at manager.kiwiticket.co.nz (<b>“Website”</b>),
          and all interactions you have with KiwiTicket (<b>“KiwiTicket”</b>, <b>“we”</b>,{" "}
          <b>“us”</b> or <b>“our”</b>) via email or the producer portal on our Website.
        </li>

        <li>
          If you register a producer account with KiwiTicket, you are deemed to have accepted these
          producer terms and conditions and agreed to be bound by them.
        </li>

        <li>
          If you are using KiwiTicket services on behalf of a company or other organisation, you
          represent to us that you have authority to do so, and in that case <b>“you”</b> refers to
          that entity as well as yourself personally.
        </li>

        <li>
          We reserve the right to vary these terms and conditions by giving 30 days notice of
          amended terms and conditions on our Website. Any variation will be effective from end of
          the 30 days notice as posted on the Website . If you list Events on the Website after this
          date, you will be deemed to be bound by the amended terms and conditions
        </li>
      </ol>
    </li>

    <li>
      Account
      <ol>
        <li>
          To create a producer account with KiwiTicket and list Events you must be at least 18 years
          of age and will need to supply your full name, name of organisation where applicable, an
          email address, any information required to issue invoices on your behalf and a bank
          account number to receive ticket sales proceeds. If we need to contact you for any reason,
          we’ll use the email address associated with your account.
        </li>

        <li>
          When you provide us with a bank account number, you represent to KiwiTicket that the bank
          account is yours or that the organisation has given you authority to use it. If this
          subsequently comes into doubt KiwiTicket can take actions set out below under{" "}
          <b>‘Violations of these terms and conditions’</b>. If the bank account number is
          inaccurate and we make payments to this account, we are not responsible for reimbursing
          you or taking any action on your behalf to recover any payments made
        </li>

        <li>
          You are responsible for maintaining the accuracy of the information you provide and the
          security of your account and password. You must tell us immediately if you think there has
          been an unauthorised use of your account. You are responsible for everything that happens
          on your account.
        </li>

        <li>
          We may from time to time allow you to implement certain permissions within your account to
          other users. If we do so, you are fully responsible for anything they do on your account
          and this is at your own risk. Any user granted these permissions is also bound by these
          producer terms and conditions.
        </li>

        <li>
          If you no longer want to have an account with KiwiTicket you can cancel it by emailing{" "}
          <Anchor href="mailto:help@kiwiticket.co.nz">help@kiwiticket.co.nz</Anchor>. Cancelling
          your account will not affect your responsibility or liability to KiwiTicket or Customers
          for Events that have not yet taken place. Cancelling your account means you will no longer
          be able to list Events or otherwise use our Service. happens on your account.
        </li>
      </ol>
    </li>

    <li>
      Conditions around events and listings
      <ol>
        <li>
          You are solely responsible for conducting any Event you list using our Service, and any
          Content you include in your listing. In particular, Kiwiticket has no responsibility for
          any costs, venue hire requirements, security, permits or licences, compliance etc. You
          must not list Events that you do not intend to hold or are unsure whether it is possible
          to hold.
        </li>

        <li>
          Any ticket to your Event that we list on our Website is a contract between you and a
          Customer; if there are particular terms and conditions you require for the sale you must
          insert them in the relevant listing. If Customers have questions about your Event we are
          not responsible for answering them; instead we will provide the Customer with your details
          to respond. You must promptly respond to any Customer query or request.
        </li>

        <li>Your listing should include a link to privacy notices or policies if you have these</li>

        <li>
          You must submit your request for listing an Event through our producer portal. KiwiTicket
          will approve or deny your listing within 24 hours of your submission. KiwiTicket may
          refuse to list your Event based if in its reasonable opinion:
          <ol>
            <li>
              it considers you have breached, or will on listing breach, these producer terms and
              conditions; or
            </li>

            <li>it breaches third party intellectual property rights;</li>

            <li>it does not contain required terms and conditions from relevant venues; or</li>

            <li>
              it is wholly or partly illegal (including by virtue of activities conducted at the
              Event), incites hate or violence, is (or is likely to be) defamatory, offensive or
              obscene, or will (or is likely to) cause harm to Customers; and
            </li>
          </ol>
          <p>
            if we subsequently discover or reasonably believe the Event breaches either of the above
            grounds we will alter or remove the relevant listing and consider whether other
            consequences may need to be applied (see{" "}
            <b>Violations of these terms and conditions etc</b> below).
          </p>
        </li>

        <li>
          If there is any inconsistency between KiwiTicket’s{" "}
          <Link href="/terms-and-conditions">customer terms and conditions</Link> and your Event
          terms and conditions, then our customer terms and conditions will prevail to the extent of
          such inconsistency. If our customer terms and conditions are silent on an issue, then your
          Event terms and conditions will prevail to the extent of any such silence
        </li>

        <li>
          You will be able to monitor how many tickets have been sold, and to who, via our producer
          portal.
        </li>
      </ol>
    </li>

    <li>
      Exclusivity
      <ol>
        <li>
          If you list an Event using our Service you cannot list it on other competitor platforms.
        </li>

        <li>
          You cannot re-sell or list an Event using our Service on behalf of another event provider
          or where that Event is already listed on a competitor platform.
        </li>
      </ol>
    </li>

    <li>
      Brand Guidelines
      <ol>
        <li>
          You may only use our Content (most especially our name and Logo) in accordance with our
          Brand Guidelines. If we believe your use of our Content is not in accordance with our
          Brand Guidelines we may alter or edit the Content, or if we consider it’s serious enough
          we will treat it as a violation of these producer terms and conditions (see{" "}
          <b>Violations of these terms and conditions etc</b> below).
        </li>
      </ol>
    </li>

    <li>
      Fees, invoicing and payment
      <ol>
        <li>
          Our fees for our Service are set out at{" "}
          <Link href="/fees">https://kiwiticket.co.nz/fees</Link>, and will apply to any Events you
          list unless you have made special prior agreement with us. These fees may be changed from
          time to time by notice in writing on our Website but any such changes will only apply to
          Events listed after notice of the change.
        </li>

        <li>
          Our fees are non-refundable even if your Event is cancelled or postponed, a Customer is
          refunded or your venue cancels.
        </li>

        <li>
          We use a third party payment provider (currently Stripe) to receive payment from the
          Customer’s bank. We don’t receive or store any information about payment methods directly.
          We deduct relevant fees when we receive the money from Stripe and then deposit the balance
          of the sale proceeds in the bank account number we hold for your account. We aim to
          deposit the balance of the sale proceeds within 3 working days of the event taking place,
          but sometimes this can take up to 7 working days.
        </li>

        <li>
          Refunds to customers are at your discretion, unless your events do not take place as,
          where or when advertised. When choosing to issue a refund you are still responsible for
          the KiwiTicket fee incurred on the sale.
        </li>

        <li>
          You are solely responsible for payment of all relevant taxes in relation to any sales
          proceeds from Events and any use of our Service.
        </li>
      </ol>
    </li>

    <li>
      Customer information, security and privacy
      <ol>
        <li>
          KiwiTicket is responsible for the privacy and security of Customers whilst it is held in
          our systems, and ensuring this is in accordance with our{" "}
          <Link href="/privacy">privacy notice</Link>. If you view and extract personal and other
          information from our systems to store, use or process elsewhere you are acting on your own
          behalf (not KiwiTicket’s) and therefore you are responsible for ensuring reasonable and
          adequate security precautions are taken and that you are acting in accordance with any
          privacy notice you posted as part of your Event listing and the Privacy Act 2020.
        </li>

        <li>
          If you post Content that includes personal information about anyone other than you, you
          must obtain all necessary consents from the relevant person or people to enable us to
          collect hold and use the Content in accordance with our{" "}
          <Link href="/privacy">privacy notice</Link> and these producer terms and conditions.
        </li>
      </ol>
    </li>

    <li>
      Liability
      <ol>
        <li>
          From time to time the Website may link to other websites, sometimes without our control.
          We don’t control, endorse or make any representations about them and won’t be held
          responsible for your use of them.
        </li>

        <li>
          To the fullest extent permitted by law, we:
          <ol>
            <li>
              exclude all responsibility and liability in relation to the Event, including from any
              Customers;
            </li>

            <li>
              exclude all responsibility and liability for any Content you display as part of an
              Event listing, in particular relating to whether it is misleading or deceptive or
              breaches third party intellectual property rights; and
            </li>

            <li>
              .exclude responsibility and liability for any loss, injury or damage however caused
              (including to any person or property), including under contract, tort, statute,
              product liability or otherwise, whether it is direct or indirect, special, incidental
              or consequential, and whether it results from your use of our Service or the Website,
              the cancellation of a venue, the cancellation or postponement of any Event, or for any
              other reason whatsoever;
            </li>
          </ol>
          provided that nothing in this clause is intended to limit the liability of KiwiTicket for
          breach of the Consumer Guarantees Act 1993 or Fair Trading Act 1986 or any consumer rights
          that cannot be limited under New Zealand law.
        </li>

        <li>
          By using our Service, you agree to defend, indemnify and hold KiwiTicket (and its agents,
          officers and employees) harmless from any and all damage (whether direct, indirect,
          incidental, consequential or otherwise) loss, liability cost and expense resulting
          directly or indirectly from any claim that:
          <ol>
            <li>your Content breached third party intellectual property rights;</li>

            <li>your Content was inaccurate, unfair or misleading;</li>

            <li>
              your Content was unlawful, offensive, threatening, libelous, defamatory, pornographic,
              obscene or otherwise objectionable;
            </li>

            <li>your Events (including an Event promoted on our Service not being held).</li>
          </ol>
        </li>
      </ol>
    </li>

    <li>
      Basis of Service
      <ol>
        <li>
          KiwiTicket grants you a limited, non-exclusive, non-transferrable, non-sublicensable right
          to use our Service to register Events and manage, track and receive sales proceeds from
          ticket sales for that Event. You appoint Kiwiticket as your agent to display Content that
          you upload on the Website, sell tickets to the Event, receive payments on your behalf and
          disburse the balance of the sale proceeds to you after deducting our Fees.
        </li>

        <li>
          You also grant us a limited, non-exclusive, non-transferrable, non-sublicensable licence
          of your Content to display it on the website and use it for the purpose of providing our
          Service. Your Content must be accurate and truthful.
        </li>

        <li>
          KiwiTicket does not promise that the Website and its service will be error-free,
          virus-free, uninterrupted, timely, meet your requirements or that it will provide specific
          results from any use of it. The Website and Service are delivered on an “as is, where is”
          basis and we expressly disclaim warranties of any kind. Nothing in this clause is intended
          to limit the liability of KiwiTicket for breach of the Consumer Guarantees Act 1993 or
          Fair Trading Act 1986 or any consumer rights that cannot be limited under New Zealand law.
        </li>

        <li>
          The Website, the KiwiTicket name and any Content displayed by KiwiTicket are protected by
          intellectual property rights. We reserve any rights not expressly granted in these
          producer terms and conditions.
        </li>

        <li>
          KiwiTicket can modify or discontinue its Service at any time, without notice and without
          liability to you. However, we’ll try to give reasonable notice where we can.
        </li>
      </ol>
    </li>

    <li>
      Violations of these terms and conditions etc
      <ol>
        <li>
          You must not:
          <ol>
            <li>
              hack into the Website or Service or use a robot, spider or similar automated device or
              process to set up an account and/ or use them;
            </li>

            <li>
              reproduce, duplicate, resell, lease or otherwise exploit the Service or any part of it
              other than as is expressly allowed under these producer terms and conditions without
              our prior written consent;
            </li>

            <li>
              reverse engineer, disassemble, decompile or otherwise attempt to discover the source
              code;
            </li>

            <li>use our Service for any illegal or unauthorised purpose.</li>
          </ol>
        </li>

        <li>
          If you don’t fully comply with these terms and conditions, misuse or abuse our Service, or
          in our reasonable opinion breach the law, then KiwiTicket reserves the right to:
          <ol>
            <li>remove your Events from its Website or alter them to conform;</li>

            <li>
              block, reverse or refund any of your payment transactions conducted via our Service;
            </li>

            <li>
              stop you from using its Service in the future (either temporarily or permanently);
            </li>

            <li>
              if required by law, disclose information about you and your use of the Website or
              Service to the Police or any relevant law enforcement body for investigation.
            </li>
          </ol>
        </li>

        <li>
          Even if we terminate your account or any part of the KiwiTicket Service, relevant
          provisions of these producer terms and conditions that are intended to survive will
          continue- Fees, invoicing and payment, Customer information, security and privacy,
          Liability, Basis of Service, Governing law and assignment.
        </li>
      </ol>
    </li>

    <li>
      Governing law and assignment
      <ol>
        <li>
          The laws of New Zealand govern these terms and conditions. These laws will apply no matter
          where you live.
        </li>

        <li>
          We can freely assign our rights and obligations under these terms and conditions without
          your consent, including to any purchaser of our service or assets.
        </li>
      </ol>
    </li>

    <li>
      Definitions
      <ol>
        <li>
          <b>“Content”</b> means any information, data, text, graphics, images etc that are
          displayed on the Website from time to time
        </li>

        <li>
          <b>“Customer”</b> refers to any purchaser of tickets through KiwiTicket.
        </li>

        <li>
          <b>“producer”</b> refers to you as the party presenting the event. This may include, but
          is not limited to, a producer, promoter, team, band or venue.
        </li>

        <li>
          <b>“Service”</b> means any functionality available to you via the Website, including but
          not limited to listing Events.
        </li>

        <li>
          <b>“You”</b> and <b>“your”</b> are all references to you, as the KiwiTicket Event producer
          and lister.
        </li>
      </ol>
    </li>
  </ol>
);
