"use client";

import { Center } from "@mantine/core";
import { redirect, usePathname } from "next/navigation";
import { Suspense } from "react";
import { useSigninCheck, useUser } from "reactfire";
import KiwiLoader from "~/components/KiwiLoader";

const CenteredKiwiLoader = (props: { "data-testid"?: string }) => (
  <Center mt="xl" data-testid={props["data-testid"]}>
    <KiwiLoader />
  </Center>
);

export const AuthGuard = ({ children }: { children: JSX.Element }) => {
  const { status, data: signInCheckResult } = useSigninCheck();
  const { status: userStatus } = useUser();
  const pathname = usePathname();

  if (status === "loading" || userStatus === "loading")
    return <CenteredKiwiLoader data-testid="loading" />;

  if (!signInCheckResult.signedIn) {
    redirect(`/auth?redirect=${pathname}`);
    return <CenteredKiwiLoader data-testid="loading" />;
  }

  return <Suspense fallback={<CenteredKiwiLoader data-testid="loading" />}>{children}</Suspense>;
};
