import {
  Avatar,
  AppShell,
  Divider,
  Group,
  Menu,
  NavLink,
  ScrollArea,
  Text,
  Button,
  rem,
  Card,
  ActionIcon,
  Stack,
  Box,
} from "@mantine/core";
import {
  IconCalendarEvent,
  IconLogout,
  IconArrowBackUp,
  IconSettings2,
  IconChevronUp,
  IconWorldLongitude,
  IconPlus,
} from "@tabler/icons-react";
import Link from "next/link";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import React, { useState } from "react";
import { Translate } from "react-i18nify";
import { useAuth } from "reactfire";
import { KiwiTicketLogo } from "~/components/KiwiTicketLogo";

import classes from "./ProducerSidebar.module.css";
import { api } from "~/trpc/react";
import { ProducerEventStatus, producerEventStatusIcons } from "../../(home)/events/schemas";
import { useProducerInContext } from "../../hooks/use-producer";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { useImpersonation } from "~/lib/hooks/impersonation";

export const ProducerSidebar = () => {
  const auth = useAuth();
  const user = auth.currentUser;
  const { signOut } = useImpersonation();
  const producer = useProducerInContext();

  const router = useRouter();
  const pathname = usePathname();
  const params = useSearchParams();
  const status = params?.get("status") ?? ("current" as ProducerEventStatus);

  const isHubsEnabled = useFeatureFlagEnabled("producer-hubs");

  const [_userMenuOpened, setUserMenuOpened] = useState(false);

  const eventCountsQuery = api.producer.events.statusCounts.useQuery();
  const eventCounts = eventCountsQuery.data ?? {};

  const eventStatusNavLink = ({
    label,
    eventStatus,
  }: { label: string; eventStatus: ProducerEventStatus }) => (
    <NavLink
      component={Link}
      label={
        <Group justify="space-between" wrap="nowrap">
          <span>{label}</span>
          {eventCounts[eventStatus] ? <span>{eventCounts[eventStatus]}</span> : null}
        </Group>
      }
      leftSection={producerEventStatusIcons[eventStatus]}
      className={classes.navlinkChild}
      href={`/producer/events?status=${eventStatus}`}
      active={status === eventStatus || (!status && eventStatus === "current")}
    />
  );

  const eventLinks = (
    <NavLink
      component={Link}
      href="/producer/events"
      active={pathname?.startsWith("/producer/events")}
      label={<Translate value="producer.events.name" />}
      leftSection={<IconCalendarEvent />}
      childrenOffset={0}
      className={classes.navlinkParent}
      rightSection={
        <ActionIcon
          size="lg"
          variant="outline"
          aria-label="New event"
          style={{ margin: "-6px -8px" }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            router.push("/producer/events?new=true");
          }}
        >
          <IconPlus size={16} color="black" />
        </ActionIcon>
      }
      opened={pathname?.startsWith("/producer/events")}
      onClick={() => router.push("/producer/events")}
    >
      <Card w="100%" p={rem(6)}>
        {eventStatusNavLink({ label: "All current", eventStatus: "current" })}
        {eventStatusNavLink({ label: "Draft", eventStatus: "draft" })}
        {eventStatusNavLink({ label: "Submitted", eventStatus: "submitted" })}
        {eventStatusNavLink({ label: "Published", eventStatus: "live" })}
        {eventStatusNavLink({ label: "Pending payout", eventStatus: "pendingPayout" })}
        <Divider mx={rem(8)} className={classes.navlinkDivider} />
        {eventStatusNavLink({ label: "Past events", eventStatus: "past" })}
        {eventStatusNavLink({ label: "Cancelled", eventStatus: "cancelled" })}
      </Card>
    </NavLink>
  );

  return (
    <AppShell.Navbar>
      <Group p="md">
        <KiwiTicketLogo size={32} />
        <Text size="lg" fw={600}>
          <Translate value="application.producer_name" />
        </Text>
      </Group>

      <Divider />

      <ScrollArea p="md" style={{ height: "100%" }}>
        <Stack gap="xs">
          <Group gap="0.5rem">{eventLinks}</Group>

          {isHubsEnabled && (
            <NavLink
              component={Link}
              href="/producer/hubs"
              className={classes.navlinkParent}
              active={pathname?.startsWith("/producer/hubs")}
              label={<Translate value="producer.hubs.name" />}
              leftSection={<IconWorldLongitude />}
              rightSection={
                <ActionIcon
                  size="lg"
                  variant="outline"
                  aria-label="New hub"
                  style={{ margin: "-6px -8px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    router.push("/producer/hubs?new=true");
                  }}
                >
                  <IconPlus size={16} color="black" />
                </ActionIcon>
              }
              opened={pathname?.startsWith("/producer/hubs")}
              onClick={() => router.push("/producer/hubs")}
            />
          )}
        </Stack>
      </ScrollArea>

      <Group p="md" gap={"xs"}>
        <NavLink
          component={Link}
          href="/"
          leftSection={<IconArrowBackUp />}
          label="kiwiticket.co.nz"
        />

        <NavLink
          component={Link}
          href="/producer/settings"
          active={pathname === "/producer/settings"}
          leftSection={<IconSettings2 />}
          label={<Translate value="producer.settings.name" />}
        />

        <Menu
          transitionProps={{ transition: "pop" }}
          onClose={() => setUserMenuOpened(false)}
          onOpen={() => setUserMenuOpened(true)}
          position="bottom-start"
        >
          <Menu.Target>
            <Button
              fullWidth
              justify="space-between"
              px="sm"
              rightSection={<IconChevronUp color="grey" />}
            >
              <Avatar
                src={producer?.avatar_url}
                alt={`${producer?.name ?? "Producer"} profile image`}
                radius="xl"
                size={24}
                bg="white"
              />
              <Box w="8px" />
              <Text fw={600} size="sm" c="grey" truncate="end">
                {producer?.name ?? "Producer"}
              </Text>
            </Button>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Label>{user?.email}</Menu.Label>
            <Menu.Divider />
            <Menu.Item color="red" onClick={signOut}>
              <Group gap="xs">
                <IconLogout size={14} />
                Sign out
              </Group>
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Group>
    </AppShell.Navbar>
  );
};
