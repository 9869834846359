"use client";

import {
  Button,
  Card,
  Checkbox,
  Divider,
  Stack,
  Title,
  Text,
  rem,
  ScrollArea,
} from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { modals } from "@mantine/modals";
import { TRPCClientError } from "@trpc/client";
import { z } from "zod";
import { ProducerTermsContent } from "~/app/(customer)/producer-terms-and-conditions/components/ProducerTerms";
import { currentProducerTermsPublishedAt } from "~/server/api/routers/account/services/terms";
import { api } from "~/trpc/react";

export const ProducerTermsAndConditionsForm = () => {
  const { mutateAsync: acceptTermsAndConditions, isPending } =
    api.account.acceptTermsAndConditions.useMutation();
  const utils = api.useUtils();

  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      producerTermsAcceptedAt: false,
    },
    validate: zodResolver(
      z.object({
        producerTermsAcceptedAt: z.literal(true, {
          errorMap: () => ({
            message: "You must read and accept the producer terms and conditions",
          }),
        }),
      }),
    ),
  });

  const onAccept = form.onSubmit(async (values) => {
    if (!values.producerTermsAcceptedAt) return;

    try {
      await acceptTermsAndConditions({ producerTermsAcceptedAt: new Date() });
      await Promise.all([utils.account.roles.refetch(), utils.producer.list.refetch()]);
    } catch (e) {
      modals.open({
        title: "Failed to accept terms and conditions",
        centered: true,
        children: (
          <>
            <Text>
              {e instanceof TRPCClientError
                ? e.message
                : "Something went wrong creating your account. Please double-check your details and try again."}
            </Text>
            <Button fullWidth onClick={() => modals.closeAll()} mt="md">
              Okay
            </Button>
          </>
        ),
      });
    }
  });

  return (
    <Stack py="xl" align="center" mah="100vh">
      <Card maw={"var(--mantine-breakpoint-md)"} w="100%" p={rem(24)} shadow="sm">
        <Title order={1} size="h2" fw={500}>
          Producer terms and conditions
        </Title>
        <Text c="dimmed" size="sm">
          Updated{" "}
          {currentProducerTermsPublishedAt.toLocaleDateString("en-NZ", {
            year: "numeric",
            month: "short",
            day: "numeric",
            timeZone: "Pacific/Auckland",
          })}
        </Text>

        <Divider mx={rem(-24)} mt={rem(24)} />

        <ScrollArea mx={rem(-24)} pl={rem(12)} pr={rem(24)} h={"95vh"}>
          <Text span size="sm">
            <ProducerTermsContent />
          </Text>
        </ScrollArea>

        <Divider mx={rem(-24)} mb={rem(24)} />

        <form onSubmit={onAccept}>
          <Stack>
            <Checkbox
              required
              label={"I have read and agree to these producer terms and conditions"}
              {...form.getInputProps("producerTermsAcceptedAt")}
            />

            <Button type="submit" variant="attention" loading={isPending}>
              Continue
            </Button>
          </Stack>
        </form>
      </Card>
    </Stack>
  );
};
