import { Anchor, Button, Modal, Stack, Text } from "@mantine/core";
import { useState } from "react";
import { api } from "~/trpc/react";

export const ProducerWelcomeModal = () => {
  const utils = api.useUtils();
  const completeWelcomeMutation = api.producer.onboarding.completeWelcomeModal.useMutation();
  const [loading, setLoading] = useState(false);

  const completeWelcome = async () => {
    setLoading(true);
    try {
      await completeWelcomeMutation.mutateAsync();
      await Promise.all([
        utils.producer.list.refetch(),
        utils.producer.onboarding.checklist.refetch(),
      ]);
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <Modal title="Refreshed experience" size="lg" centered opened onClose={completeWelcome}>
      <Stack>
        <Text>Welcome to our refreshed producer experience!</Text>
        <Text>
          We hope you find this cleaner and easier to use and we would love your feedback if you see
          anything that could be improved.
        </Text>
        <Text>
          Get in touch with us via{" "}
          <Anchor href="mailto:help@kiwiticket.co.nz">help@kiwiticket.co.nz</Anchor> at any time.
        </Text>
        <Text>
          Happy producing!
          <br />
          <Text span>- The KiwiTicket team</Text>
        </Text>

        <Button variant="attention" onClick={completeWelcome} loading={loading}>
          Get started!
        </Button>
      </Stack>
    </Modal>
  );
};
