import {
  AppShell,
  Center,
  Divider,
  Group,
  NavLink,
  Select,
  SelectProps,
  Stack,
  Text,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import {
  Icon,
  IconCalendarEvent,
  IconCheck,
  IconProps,
  IconSelector,
  IconSettings2,
  IconWorldLongitude,
} from "@tabler/icons-react";
import Link from "next/link";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import React, { ForwardRefExoticComponent, ReactNode, RefAttributes } from "react";
import { ProducerEventStatus, producerEventStatusIcons } from "../../(home)/events/schemas";
import { api } from "~/trpc/react";
import { Translate } from "react-i18nify";
import { useFeatureFlagEnabled } from "posthog-js/react";

import classes from "./ProducerBottomNavigationBar.module.css";

type NavItem = {
  key?: React.Key;
  label?: ReactNode;
  link: string;
  icon: ForwardRefExoticComponent<Omit<IconProps, "ref"> & RefAttributes<Icon>>;
  "data-testid"?: string;
};

const determineNavItems = ({ isHubsEnabled }: { isHubsEnabled?: boolean }): NavItem[] => {
  const items = [
    {
      key: "events",
      label: <Translate value="producer.events.name" />,
      link: "/producer/events",
      icon: IconCalendarEvent,
      "data-testid": "nav-item-events",
    },
  ];

  if (isHubsEnabled)
    items.push({
      key: "hubs",
      label: <Translate value="producer.hubs.name" />,
      link: "/producer/hubs",
      icon: IconWorldLongitude,
      "data-testid": "nav-item-hubs",
    });

  items.push({
    key: "settings",
    label: <Translate value="producer.settings.name" />,
    link: "/producer/settings",
    icon: IconSettings2,
    "data-testid": "nav-item-settings",
  });

  return items;
};

export const ProducerBottomNavigationBar = () => {
  const isDesktopView = useMediaQuery("(min-width: 768px)");
  const pathname = usePathname();
  const isHubsEnabled = useFeatureFlagEnabled("producer-hubs");

  const items = determineNavItems({ isHubsEnabled });
  const navigationItems = items.map((item) => {
    return items.length <= 2 ? (
      <NavLink
        key={item.key}
        label={item.label}
        leftSection={<item.icon size="2rem" />}
        component={Link}
        href={item.link}
        active={pathname === item.link}
        styles={{
          root: { display: "flex", justifyContent: "center" },
          label: { fontSize: "1rem" },
          body: { flex: "initial" },
          section: { marginRight: "0.5rem" },
        }}
        p="0.5rem"
        data-testid={item["data-testid"]}
      />
    ) : (
      <NavLink
        key={item.key}
        label={
          <Center>
            <item.icon size="2rem" />
          </Center>
        }
        component={Link}
        href={item.link}
        active={pathname === item.link}
        styles={{
          root: { display: "flex", justifyContent: "center" },
        }}
        data-testid={item["data-testid"]}
      />
    );
  });

  return (
    <AppShell.Footer display={isDesktopView ? "none" : undefined}>
      <Stack h="100%" gap={0} justify="end">
        {pathname?.startsWith("/producer/events") && (
          <>
            <MobileProducerEventFilters />
            <Divider />
          </>
        )}

        <Group grow p="1rem">
          {navigationItems}
        </Group>
      </Stack>
    </AppShell.Footer>
  );
};

const MobileProducerEventFilters = () => {
  const router = useRouter();
  const params = useSearchParams();
  const status = (params?.get("status") ?? "current") as ProducerEventStatus;

  const eventCountsQuery = api.producer.events.statusCounts.useQuery();
  const eventCounts = eventCountsQuery.data ?? {};

  const renderSelectOption: SelectProps["renderOption"] = ({ option, checked }) => (
    <Group flex="1" gap="xs">
      {producerEventStatusIcons[option.value as ProducerEventStatus]}
      {option.label}
      <Text style={{ marginInlineStart: "auto" }}>{eventCounts[option.value]}</Text>
      {checked && <IconCheck size="1rem" stroke="2.5" />}
    </Group>
  );

  return (
    <Select
      size="md"
      allowDeselect={false}
      value={status}
      onChange={(value) => router.push(`/producer/events?status=${value}`)}
      placeholder="Filter events by status"
      px="md"
      py="sm"
      data={[
        { value: "current", label: "All current" },
        { value: "draft", label: "Draft" },
        { value: "submitted", label: "Submitted" },
        { value: "live", label: "Published" },
        { value: "pendingPayout", label: "Pending payout" },
        { value: "past", label: "Past" },
        { value: "cancelled", label: "Cancelled" },
      ]}
      renderOption={renderSelectOption}
      leftSectionPointerEvents="none"
      leftSection={producerEventStatusIcons[status]}
      rightSectionPointerEvents="none"
      rightSection={
        <Group wrap="nowrap" justify="end" gap={4}>
          {eventCounts[status]}
          <IconSelector />
        </Group>
      }
      classNames={{
        option: classes.option,
        input: classes.input,
        section: classes.section,
      }}
    />
  );
};
