import {
  IconLayoutDashboard,
  IconPencil,
  IconProgressCheck,
  IconPigMoney,
  IconActivityHeartbeat,
  IconHistory,
  IconBan,
} from "@tabler/icons-react";
import { z } from "zod";

export const producerEventStatuses = z.enum([
  "current",
  "draft",
  "submitted",
  "pendingPayout",
  "live",
  "cancelled",
  "past",
]);

export const producerEventSortOptions = z.object({
  created_at: z.union([z.undefined(), z.literal("asc"), z.literal("desc")]),
  updated_at: z.union([z.undefined(), z.literal("asc"), z.literal("desc")]),
  title: z.union([z.undefined(), z.literal("asc"), z.literal("desc")]),
  start_time: z.union([z.undefined(), z.literal("asc"), z.literal("desc")]),
  end_time: z.union([z.undefined(), z.literal("asc"), z.literal("desc")]),
  admin_status: z.union([z.undefined(), z.literal("asc"), z.literal("desc")]),
});

export type ProducerEventStatus = z.infer<typeof producerEventStatuses>;

export const producerEventStatusIcons: Record<ProducerEventStatus, JSX.Element> = {
  current: <IconLayoutDashboard />,
  draft: <IconPencil />,
  submitted: <IconProgressCheck />,
  pendingPayout: <IconPigMoney />,
  live: <IconActivityHeartbeat />,
  past: <IconHistory />,
  cancelled: <IconBan />,
};
