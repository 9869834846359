"use client";

import { Modal } from "@mantine/core";
import { ProducerIdentityForm } from "../ProducerIdentityForm";
import { producers } from "@prisma/client";

export const FirstTimeProducerIdentitySetupModal = ({ producer }: { producer: producers }) => {
  return (
    <Modal
      title="Producer identity"
      size="lg"
      opened
      onClose={() => {
        /* don't allow the modal to be closed */
      }}
      withCloseButton={false}
    >
      <ProducerIdentityForm producer={producer} />
    </Modal>
  );
};
